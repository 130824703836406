import React, { useEffect, createRef } from "react";

const TermageddonPolicy = () => {
  const policyDiv = createRef();

  useEffect(() => {
    fetch(
      "https://app.termageddon.com/api/policy/UzBJd1drOUxXakpNTjJadlpHYzlQUT09?no-title=true"
    )
      .then((response) => response.text())
      .then((text) => {
        if (policyDiv.current) {
          policyDiv.current.innerHTML = text;
        }
      });
  }, []);

  return <div className="termageddon-container" ref={policyDiv}></div>;
};

export default TermageddonPolicy;
