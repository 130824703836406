import React from "react";
import TermageddonPolicy from "../components/Termageddon";
import "../styles/pages/privacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="cs-privacy-policy">
      <div className="cs-privacy-policy__inner">
        <h1>Privacy Policy</h1>
        <TermageddonPolicy />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
